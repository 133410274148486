import React from "react"
import { Helmet } from "react-helmet"
import { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
    const data = useStaticQuery<GatsbyTypes.TestQuery>(graphql`
        query Test {
            allGhostPost(sort: { order: DESC, fields: [published_at] }) {
                edges {
                    node {
                        ...GhostPostFields
                    }
                }
            }
        }
    `)

    const posts = data.allGhostPost.edges
    const post = posts[0].node

    useEffect(() => console.log(posts))

    return (
        <>
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <div className="mx-auto container">
                <article className="blog-format-text" key={post.title}>
                    {post.feature_image ? (
                        <figure className="post-feature-image">
                            <img src={post.feature_image} alt={post.title} />
                        </figure>
                    ) : null}
                    <section className="post-full-content">
                        <h1 className="content-title">{post.title}</h1>
                        <hr /> {/* The main post content */}
                        <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        />
                    </section>
                </article>
            </div>
        </>
    )
}
